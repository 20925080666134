import React from 'react';
import {isWebpSupported} from 'react-image-webp/dist/utils';

const HomeCapabilities = () => {
   return (
      <>
         <section className="capabilities__area p-relative black-bg-2 pt-180 pb-155 fix">
            <div className="capabilities__thumb p-absolute" style={
               { 
                  background: isWebpSupported()? `url(assets/img/capabilities/capabilities-img.webp)` : `url(assets/img/capabilities/capabilities-img.jpg)`,
                  
                  backgroundPosition: 'center', 
                  backgroundSize:'cover'
               }
            }>
            </div>
            <div className="container">
               <div className="row">
                  <div className="col-xl-5 col-lg-6">
                     <div className="capabilities__content wow fadeInUp" data-wow-delay=".4s">
                        <div className="section__title section__title-2 mb-60">
                           <span className="">JRD Transportation</span>
                           <h2 className="white-color">Company that believes in the power of people.</h2>
                        </div>
                        <p className="white-color">JRD Transportation has been operated trucking company since 2004.</p>
                        <p className="white-color">We have extensive expertisein managing, dispatching, andd riving FTL and LTL Dry shipments.</p>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeCapabilities;