import React from 'react';
import { FaCheck } from 'react-icons/fa';
import {isWebpSupported} from 'react-image-webp/dist/utils';

const TeamDetailsArea = () => {
   return (
      <>
         <section className="team__details pt-120 pb-160">
            <div className="container">
               <div className="team__details-inner p-relative white-bg">
                  <div className="row">
                     <div className="col-xl-6 col-lg-6">
                        <div className="team__details-img w-img mr-70">
                           {isWebpSupported()?
                              <img src="assets/img/team/details/team-01.webp" alt="JRD Transportation"/>
                              :
                              <img src="assets/img/team/details/team-01.jpg" alt="JRD Transportation"/>
                           }
                        </div>
                     </div>
                     <div className="col-xl-6 col-lg-6">
                        <div className="team__details-content pt-50 pb-50">
                           <span>JRD Transportation Hiring</span>
                           <h3>Company Team Drivers</h3>
                           <h4>Just now <strong>0,75$</strong> per mile!</h4>
                           <p className="mt-25">What we offer:</p>
                           <div className="about__list">
                              <ul>
                                 <li><span><i > <FaCheck/> </i>5000-7000 miles per week.</span></li>
                                 <li><span><i > <FaCheck/> </i>Direct deposit every Friday.</span></li>
                                 <li><span><i > <FaCheck/> </i>Great home time.</span></li>
                                 <li><span><i > <FaCheck/> </i>Fully loaded, Newer 2023-2025 Freightliner & Volvo trucks, Fridge!</span></li>
                                 <li><span><i > <FaCheck/> </i>24/7 Professional Dispatch Team.</span></li>
                                 <li><span><i > <FaCheck/> </i>4/7 Professional Safety & Fleet Team.</span></li>
                                 <li><span><i > <FaCheck/> </i>Fuel cards</span></li>
                                 <li><span><i > <FaCheck/> </i>2024-2025 trailers</span></li>
                                 <li><span><i > <FaCheck/> </i>24/7 Professional Safety and Fleet Team</span></li>
                              </ul>
                           </div>
                           <p className="mt-25">Requirements:</p>
                           <div className="about__list">
                              <ul>
                                 <li><span><i > <FaCheck/> </i>CDL CLASS A. OTR only!</span></li>
                                 <li><span><i > <FaCheck/> </i>Min 6 months of CDL experience.</span></li>
                                 <li><span><i > <FaCheck/> </i>Must pass Drug & Road test.</span></li>
                              </ul>
                           </div>
                           
                           <a href="tel:+16145340560" className="z-btn mt-10">Apply Now<i > <FaCheck/> </i></a>
                           
                        </div>
                     </div>
                  </div>
               </div>
               
            </div>
         </section>
      </>
   );
};

export default TeamDetailsArea;