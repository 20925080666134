import React from 'react';
import { Link } from 'react-router-dom';

import {isWebpSupported} from 'react-image-webp/dist/utils';

const CommonCtaArea = () => {
   return (
      <>
         <section className="cta__area pt-180 pb-155" style={
            { 
               background: isWebpSupported()? `url(assets/img/cta/cta-bg.webp)` : `url(assets/img/cta/cta-bg.jpg)`, 
               backgroundPosition: 'center', 
               backgroundSize: 'cover' 
            }
         } >
            <div className="container p-relative">
               <div className="row">
                  <div className="col-xl-10 offset-xl-1">
                     <div className="cta__content text-center">
                        <span >Hiring Company Team Drivers</span>
                        <h1>Drive for JRD Transportation</h1>
                        <div className="cta__btn">
                           <Link to="/careers" className="z-btn z-btn-white mb-30">View more</Link>
                           <Link to="/contact" className="z-btn z-btn-transparent mb-30">Contact Us</Link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default CommonCtaArea;