import React from 'react';
import {isWebpSupported} from 'react-image-webp/dist/utils';

const HomeTwoSingleFeature = ({icon,image,title,text}) => {
   return (
      <>
         <div className="features__item features__item-2 white-bg fix mb-30">
            <div className="features__thumb-2" style={
               { 
                  background: isWebpSupported()? `url(assets/img/features/02/features-${image}.webp)` : `url(assets/img/features/02/features-${image}.jpg)`, 
                  backgroundPosition: 'center', 
                  backgroundSize: 'cover'
               }
            } >
            </div>
            <div className="features__content-2">
               <div className="features__icon features__icon-2">
                  <i > {icon} </i>
               </div>
               <h3>{title}</h3>
               <p>{text}</p>
            </div>
         </div>
      </>
   );
};

export default HomeTwoSingleFeature;