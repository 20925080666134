import Slider from "react-slick";
import SingleBrandItem from "../../../components/SingleBrandItem/SingleBrandItem";

const HomeBrands = () => {
   // slick setting
   const settings = {
      autoplay: false,
      autoplaySpeed: 10000,
      dots: false,
      arrows: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
         {
            breakpoint: 1024,
            settings: {
               slidesToShow: 4,
               slidesToScroll: 1,
               infinite: true,
            }
         },
         {
            breakpoint: 767,
            settings: {
               slidesToShow: 3,
               slidesToScroll: 1,
            }
         },
         {
            breakpoint: 576,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
            }
         },
      ]

   };
   return (
      <>
         <section className="brand__area p-relative pt-160 pb-50">
            <div className="container p-relative">
               <div className="row">
                  <div className="col-12">
                     <div className="section__title section__title-3 text-center wow fadeInUp" data-wow-delay=".2s">
                        <h2>We're plated to operate in every one of the 48 contiguous United States</h2>
                     </div>
                     <div className="brand__subtitle text-center mb-90 wow fadeInUp" data-wow-delay=".4s">
                        <p>We feel happy to create a long-lasting and a great partnership with our clients, therefore companies choose "JRD Transportation" for dependability. </p>
                     </div>
                  </div>
               </div>
               <div className="row">

                  <Slider className='brand-active' {...settings}>
                     <SingleBrandItem img_1="1" />
                     <SingleBrandItem img_1="2" />
                     <SingleBrandItem img_1="3" />
                     <SingleBrandItem img_1="4" />
                     <SingleBrandItem img_1="2" />
                  </Slider>

               </div>
            </div>
         </section>

      </>
   );
};

export default HomeBrands;