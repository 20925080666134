
const featuredWorksData = [
    {
        id:1,
        img:'assets/img/case/case-1-full.jpg',
        thumb: 'assets/img/case/case-1.jpg',
        title:'Freightliner',
        subtitle:'Our Trucks',
        category:'trucks',
    },
    {
        id:2,
        img:'assets/img/case/case-2-full.jpg',
        thumb: 'assets/img/case/case-2.jpg',
        title:'Peterbilt',
        subtitle:'Our Trucks',
        category:'trucks',
    },
    {
        id:3,
        img:'assets/img/case/case-3-full.jpg',
        thumb: 'assets/img/case/case-3.jpg',
        title:'Leadership & Change',
        subtitle:'Volvo',
        category: 'trucks',
    },
    {
        id:4,
        img:'assets/img/case/case-4-full.jpg',
        thumb: 'assets/img/case/case-4.jpg',
        title:'Great Dane',
        subtitle:'Our Trailers',
        category: 'trailers',
    },
    {
        id:5,
        img:'assets/img/case/case-5-full.jpg',
        thumb: 'assets/img/case/case-5.jpg',
        title:'Great Dane',
        subtitle:'Our Trailers',
        category: 'trailers',
    },
    {
        id:6,
        img:'assets/img/case/case-6-full.jpg',
        thumb: 'assets/img/case/case-6.jpg',
        title:'Great Dane',
        subtitle:'Our Trailers',
        category:'trailers',
    },
    {
        id:7,
        img:'assets/img/case/case-7-full.jpg',
        thumb: 'assets/img/case/case-7.jpg',
        title:'Peterbilt',
        subtitle:'Our Trucks',
        category:'trucks',
    },
    {
        id: 8,
        img:'assets/img/case/case-8-full.jpg',
        thumb: 'assets/img/case/case-8.jpg',
        title:'Great Dane',
        subtitle:'Our Trailers',
        category:'trailers',
    },

]

export default featuredWorksData;