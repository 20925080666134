import React from 'react';
import { Helmet } from "react-helmet";

const PageHelmet = ({ pageTitle }) => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{pageTitle} | A partnership based on trust!</title>
                <meta name="robots" content="noindex, follow" />
                <meta name="description" content="We focus only on providing the best long-lasting, and respectful relationships with our customers and brokers." />
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                <meta name="author" content="Nikba Creative Studio" />
            </Helmet>
        </>
    );
};

export default PageHelmet;