import React from 'react';
import { Link } from 'react-router-dom';
import {isWebpSupported} from 'react-image-webp/dist/utils';

const CommonPageHeader = ({ title, subtitle}) => {
   return (
      <>
         <section className="page__title p-relative d-flex align-items-center fix" style={
            { 
               background: isWebpSupported()? `url(assets/img/page-title/page-title-1.webp)` : `url(assets/img/page-title/page-title-1.jpg)`, 
               backgroundPosition: 'center', 
               backgroundSize: 'cover'
            }
         }>
            <div className="container p-relative">
               <div className="row">
                  <div className="col-xl-12">
                     <div className="page__title-content mt-100">
                        <h2>{title}</h2>

                        <nav aria-label="breadcrumb">
                           <ol className="breadcrumb">
                              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                              <li className="breadcrumb-item active" aria-current="page">{subtitle}</li>
                           </ol>
                        </nav>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default CommonPageHeader;