import React from 'react';
import SingleService from '../../../components/SingleService/SingleService';

const HomeServices = () => {
   return (
      <>
         <section className="services__area pt-115 pb-80">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                     <div className="section__title section__title-3 text-center mb-90 wow fadeInUp" data-wow-delay=".2s">
                        <span>Our Services</span>
                        <h2>What you get with<br /> JRD Transportation</h2>
                     </div>
                  </div>
               </div>
               <div className="row">

                  <SingleService icon="1" title="Dedicated" text="We examines your needs and provides a specifically tailored solution that allows you to save time and money." />
                  <SingleService icon="2" title="Team Expedited" text="100% of our fleet being operated by team drivers, we can get your freight where it needs to be, quickly." />
                  <SingleService icon="3" title="Drop and Hook" text="We operates over 120 dry-van trailers and able to provide pools for shippers all across the country." />
                  <SingleService icon="4" title="FTL" text="JRD Transportation is a Full Truck Load Carrier with a fleet of 50 Trucks and 120 Dry Van Trailers." />
                  <SingleService icon="5" title="OTR" text="JRD Transportation’s fleet services all continental 48 states of America." />
                  <SingleService icon="6" title="Real-Time Visibility " text="All units are equipped with (Global Positioning System) satellite devices." />
                  <SingleService icon="7" title="24/7 Support" text="We operates 24/7/365 with highly qualified employees." />
                  <SingleService icon="8" title="Logistics" text="We provide steady logistics solutions for all of your needs" />

               </div>
            </div>
         </section>
      </>
   );
};

export default HomeServices;