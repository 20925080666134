import React from 'react';
import HomeSingleFeature from '../../../components/HomeSingleFeature/HomeSingleFeature';
import { FiUsers } from 'react-icons/fi';
import { FaFlagUsa, FaHandHoldingHeart } from 'react-icons/fa';
import { BsTruck } from 'react-icons/bs';
import { GiGps } from 'react-icons/gi';
import { BiSupport } from 'react-icons/bi';


const HomeFeatures = () => {
   return (
      <>
         <section className="features__area pb-100 mt--100 d-none d-sm-block wow fadeInUp" data-wow-delay=".5s">
            <div className="container">
               <div className="features__inner fix">
                  <div className="row g-0 ">

                     <HomeSingleFeature icon={<FiUsers />} title="Team Drivers" image="1" />
                     <HomeSingleFeature icon={<BsTruck />} title="Full Truck Load" image="2" />
                     <HomeSingleFeature icon={<FaFlagUsa />} title="OTR" image="3" />
                     <HomeSingleFeature icon={<FaHandHoldingHeart />} title="Dedicated" image="4" />
                     <HomeSingleFeature icon={<GiGps />} title="Real-Time Visibility" image="5" />
                     <HomeSingleFeature icon={<BiSupport />} title="24/7 Support" image="6" />

                  </div>
               </div>
            </div>
         </section>

      </>
   );
};

export default HomeFeatures;