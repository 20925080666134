import { useState } from 'react';
import { FaFacebookF, FaInstagram, FaPhoneAlt, FaEnvelope, FaLinkedinIn } from 'react-icons/fa';
import { Link, NavLink } from 'react-router-dom';
import Sidebar from '../../../components/Sidebar/Sidebar';
import useGlobalContext from '../../../hooks/useGlobalContext';

const HomeOneHeader = () => {
   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   const { stickyMenu } = useGlobalContext();
   return (
      <>
         <header>
            <div className="header__area p-relative header__transparent">
               <div className="header__top d-none d-md-block">
                  <div className="container">
                     <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-5 col-md-4">
                           <div className="header__social">
                              <ul>
                                 <li><a href="https://www.facebook.com/JRD.Transportation" target="_blank" rel="noreferrer"><i ><FaFacebookF/></i> </a></li>
                                 <li><a href="https://www.instagram.com/jrdtransport/" target="_blank" rel="noreferrer"><i ><FaInstagram/> </i></a> </li>
                                 <li><a href="https://www.linkedin.com/company/83481750/" target="_blank" rel="noreferrer"><i ><FaLinkedinIn/></i></a></li>
                              </ul>
                           </div>
                        </div>
                        <div className="col-xl-6 col-lg-7 col-md-8">
                           <div className="header__info f-right">
                              <ul>
                                 <li>
                                    <a href="tel:+16145340560">
                                       <i > <FaPhoneAlt/> </i>
                                       +1 614-534-0560
                                    </a>
                                 </li>
                                 <li>
                                    <a href="mailto:info@jrdtrans.com">
                                       <i > <FaEnvelope/> </i>
                                       dispatch@jrdtrans.com
                                    </a>
                                 </li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div id="header__sticky" className={stickyMenu ? "sticky header__bottom" : "header__bottom"}>
                  <div className="container">
                     <div className="row align-items-center">
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                           <div className="logo">
                              <NavLink to="/">
                                 <img src="assets/img/logo/logo-white.svg"  height="40" alt="JRD Transportation"/>
                              </NavLink>
                           </div>
                           <div className="logo-gradient">
                              <NavLink to="/">
                                 <img src="assets/img/logo/logo.svg" height="40" alt="JRD Transportation"/>
                              </NavLink>
                           </div>
                        </div>
                        <div className="col-xl-9 col-lg-9 col-md-6 col-sm-6 col-6">
                           <div className="header__bottom-right d-flex justify-content-end align-items-center">
                             <div className="main-menu menu_wrapper_one">
                                 <nav id="mobile-menu">
                                    <ul>
                                       <li><NavLink  to="/home">Home</NavLink></li>
                                       <li><NavLink to="/about">About Us </NavLink></li>
                                       <li><NavLink to="/services">Services</NavLink></li>
                                       <li><NavLink to="/portfolio">Equipment</NavLink></li>
                                       <li><NavLink to="/careers">Careers</NavLink></li>
                                       <li><NavLink to="/contact">Contact Us</NavLink></li>
                                    </ul>
                                 </nav>
                              </div>
                              <div className="header__btn d-none d-sm-block d-lg-none d-xl-block ml-50">
                                 <Link to="/careers" className="z-btn z-btn-white">Join our team</Link>
                              </div>
                              <div onClick={handleShow} className="sidebar__menu d-lg-none">
                                 <div className="sidebar-toggle-btn" id="sidebar-toggle">
                                    <span className="line"></span>
                                    <span className="line"></span>
                                    <span className="line"></span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="body-overlay-2"></div>
            </div>
         </header>



         <Sidebar show={show} handleClose={handleClose} />

      </>
   );
};

export default HomeOneHeader;