import React from 'react';
import { BsSpeedometer2 } from 'react-icons/bs';
import { BiSupport } from 'react-icons/bi';
import { IoHelpBuoyOutline } from 'react-icons/io5';
import { FaRegHandshake } from 'react-icons/fa';
import HomeTwoSingleFeature from '../../../components/HomeTwoSingleFeature/HomeTwoSingleFeature';

const HomeTwoFeatures = () => {
   return (
      <>
         <section className="features__area pt-115 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-xl-5 col-lg-6">
                     <div className="features__content-left">
                        <div className="section__title section__title-h2 mb-25">
                           <span>Our advantage</span>
                           <h2>Are you interested in working with a professional Trucking Company? </h2>
                        </div>
                        <p>We supply all the equipment and skills necessary to tackle just about every type of job our clients have for us, no matter how big or small it may be.</p>
                     </div>
                  </div>
                  <div className="col-xl-6 offset-xl-1 col-lg-6">
                     <div className="features__content-right">
                        <div className="row">
                           <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">

                              <HomeTwoSingleFeature 
                                 icon={<IoHelpBuoyOutline />} 
                                 image="1"
                                 title="Safe & Secure" 
                                 text="Achieving through a certified integrated management system covering quality" 
                              />
                              
                              <HomeTwoSingleFeature 
                                 icon={<BsSpeedometer2 />} 
                                 image="2"
                                 title="Fast Delivery" 
                                 text="On time delivery is a critical measure of a company's efficiency and service." 
                              />

                           </div>

                           <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">

                              <HomeTwoSingleFeature 
                                 icon={<BiSupport />} 
                                 image="3"
                                 title="24/7 Support" 
                                 text="We provide 24/7 support for our clients and drivers along with real time location service for all loads." 
                              />
                              
                              <HomeTwoSingleFeature 
                                 icon={<FaRegHandshake />} 
                                 image="4"
                                 title="Respect" 
                                 text="we’re a family driven by loyalty, truthfulness and respect. It’s how we do business. It’s who we are." 
                              />

                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeTwoFeatures;