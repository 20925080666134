import { CgArrowLongRight } from 'react-icons/cg';
import { FaCheck } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import {isWebpSupported} from 'react-image-webp/dist/utils';

const HomeAbout = () => {
   return (
      <>
         <section className="about__area pb-200 pt-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 pr-0 col-lg-6">
                     <div className="about__thumb m-img">
                        {isWebpSupported()?
                           <img src="assets/img/about/about-1.webp" alt="JRD Transportation"/>
                           :
                           <img src="assets/img/about/about-1.jpg" alt="JRD Transportation"/>
                        }
                        <div className="about__shape">
                           <img src="assets/img/about/red-shape.svg" alt=""/>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-5 offset-xl-1 col-lg-5 offset-lg-1">
                     <div className="about__content">
                        <div className="section__title mb-25">
                           <span>JRD Transportation</span>
                           <h2>A partnership based on trust!</h2>
                        </div>
                        <p>We focus only on providing the best long-lasting, and respectful relationships with our customers and brokers.<br />
                        The key to our successful activity is our team.<br />
                        The company's employees are specialists of the highest level with extensive experience in the transport industry and in the field of logistics.</p>
                        <div className="about__list">
                           <ul>
                              <li ><span><i > <FaCheck/> </i>Team Service</span></li>
                              <li ><span><i > <FaCheck/> </i>New vehicles</span></li>
                              <li ><span><i > <FaCheck/> </i>Truckload Services</span></li>
                           </ul>
                        </div>
                        <Link to="/services" className="z-btn " >What we do<i > <CgArrowLongRight /> </i></Link>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeAbout;